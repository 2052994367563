import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app.routing";

import { AppComponent } from "./app.component";
import { EunoComponent } from "./component/euno/euno.component";
import { LandingComponent } from "./component/landing/landing.component";
import { NavbarComponent } from "./component/shared/navbar/navbar.component";
import { FooterComponent } from "./component/shared/footer/footer.component";

import { CommonModule } from "@angular/common";
import { RedirectComponent } from "./component/redirect/redirect.component";

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NavbarComponent,
    FooterComponent,
    EunoComponent,
    RedirectComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
