import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EunoService } from "src/app/service/euno/euno.service";
import { ActivatedRoute } from "@angular/router";
import { Euno } from "src/app/models/euno";

@Component({
  selector: "app-euno",
  templateUrl: "./euno.component.html",
  styleUrls: ["./euno.component.css"],
})
export class EunoComponent implements OnInit {
  eunoObject: Euno;
  isActivated = false;
  eunoForm: FormGroup;
  URL_REGEXP =
    /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;

  constructor(
    private formBuilder: FormBuilder,
    private eunoService: EunoService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let _id;
    this.activatedRoute.params.subscribe((params) => (_id = params["eunoId"]));
    this.eunoForm = this.formBuilder.group({
      _id: [_id ? _id : "", Validators.required],
      eunoKey: ["", Validators.required],
      redirectUrl: ["https://", Validators.required],
      notes: "",
      ownerEmail: "",
      itemName: "",
    });

    if (_id) {
      this.eunoService.get(_id).subscribe((data) => {
        this.eunoObject = data.euno;
        if (this.eunoObject.isActivated) {
          this.isActivated = true;
          this.eunoForm.patchValue({
            redirectUrl: [this.eunoObject.redirectUrl],
            notes: this.eunoObject.notes,
            ownerEmail: this.eunoObject.ownerEmail,
            itemName: this.eunoObject.itemName,
          });
          this.eunoForm.get("ownerEmail").disable();
        } else {
          this.eunoForm.patchValue({
            eunoKey: this.eunoObject.eunoKey,
          });
        }
      });
    }
  }

  onFormSubmit(event: Event): void {
    this.eunoService.update(this.eunoForm.value).subscribe((response) => {
      console.log(
        "Your update request has been submitted",
        this.eunoForm.value
      );
    });
  }
}
