import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { EunoComponent } from "./component/euno/euno.component";
import { RedirectComponent } from "./component/redirect/redirect.component";
import { LandingComponent } from "./component/landing/landing.component";

const routes: Routes = [
  // { path: "home", component: HomeComponent },
  // { path: "faq", component: FaqComponent },
  // { path: "user-profile", component: ProfileComponent },
  // { path: "register", component: SignupComponent },
  { path: "welcome", component: LandingComponent },
  // { path: "login", component: LoginComponent },
  { path: "manager", component: EunoComponent },
  { path: "manager/:eunoId", component: EunoComponent },
  { path: "redirect/:eunoId", component: RedirectComponent },
  { path: "", redirectTo: "welcome", pathMatch: "full" },
];

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes)],
  exports: [],
})
export class AppRoutingModule {}
