import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EunoFetchResponse } from "src/app/models/api";
import { Euno } from "src/app/models/euno";

const baseUrl = "https://api.eunoqr.com";

@Injectable({
  providedIn: "root",
})
export class EunoService {
  constructor(private http: HttpClient) {}

  get(id): Observable<EunoFetchResponse> {
    return this.http.get<EunoFetchResponse>(`${baseUrl}/fetch/${id}`);
  }

  // create(data): Observable<any> {
  //   return this.http.post(`${baseUrl}/${update}`, data);
  // }

  update(data: Euno): Observable<any> {
    return this.http.put(`${baseUrl}/update`, data);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${baseUrl}/${id}`);
  }

  deleteAll(): Observable<any> {
    return this.http.delete(baseUrl);
  }

  findByTitle(title): Observable<any> {
    return this.http.get(`${baseUrl}?title=${title}`);
  }
}
