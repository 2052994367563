import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { EunoService } from "src/app/service/euno/euno.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Euno } from "src/app/models/euno";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-euno-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.css"],
})
export class RedirectComponent implements OnInit {
  eunoObject: Euno;
  eunoObjectLoaded: Promise<boolean>;
  timeLeft: number = 9;
  redirectButtonText: string;
  interval;
  isPaused = false;
  isLink = false;
  isImage = false;
  isSpotify = false;
  spotifyTrackUrl: SafeResourceUrl = "";
  imgUrl: SafeResourceUrl = "";
  @ViewChild("iframe") iframe: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private eunoService: EunoService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    let _id;
    this.activatedRoute.params.subscribe((params) => (_id = params["eunoId"]));
    this.eunoService.get(_id).subscribe((data) => {
      if (!data.euno.isActivated) {
        this.router.navigate(["/manager", _id]);
        return;
      }
      const euno = data.euno;
      this.eunoObject = euno;
      this.eunoObjectLoaded = Promise.resolve(true);
      const isSpotify = isSpotifyUrl(euno.redirectUrl);
      if (isSpotify) {
        this.isSpotify = true;
        const url = extractTrackId(euno.redirectUrl);
        this.spotifyTrackUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://open.spotify.com/embed/track/${url}?utm_source=generator&theme=0`
        );
        return;
      }
      const isImage = isImageUrl(euno.redirectUrl);
      if (isImage) {
        this.isImage = true;
        this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          euno.redirectUrl
        );
        return;
      }
      this.isLink = true;
      this.startTimer();
    });
  }

  ngOnDestroy() {
    this.pauseTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.redirectButtonText = `Redirect in ${this.timeLeft}`;
      } else {
        this.redirectPage();
      }
    }, 1000);
  }

  redirectPage() {
    this.document.location.href = this.eunoObject.redirectUrl;
  }

  pauseTimer() {
    this.isPaused = true;
    clearInterval(this.interval);
  }
}

function extractTrackId(url) {
  const startIndex = url.lastIndexOf("/") + 1; // Get the index after the last '/'
  const endIndex = url.indexOf("?"); // Get the index of '?'
  return url.substring(startIndex, endIndex); // Extract substring between '/' and '?'
}

function isImageUrl(url) {
  return (
    url.toLowerCase().endsWith(".gif") || url.toLowerCase().endsWith(".webp")
  );
}

function isSpotifyUrl(url) {
  return url.includes("spotify.com/track");
}
